
















































































import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AnomalyCode,
  AnomalyOptions,
  BusinessError,
  RentalAgreement,
  ANOMALY_ERROR,
  AnomaliesChecked,
} from "@edmp/api";
import { rentalAgreementsStore } from "@/store";

import Anomalies, { AnomaliesView } from "../anomalies/Anomalies.vue";
import { cloneDeep } from "lodash";
import { AxiosError } from "axios";

export interface RealEstateRentsDeleteConfirmView {
  deleteOrUndoRentalAgreement: () => Promise<boolean>;
}

export default defineComponent({
  name: "RealEstateRentsDeleteConfirm",
  components: { Anomalies },
  props: {
    rentalAgreementId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    // Data
    const rentalAgreement: Ref<RentalAgreement | undefined> = ref();
    const openModal = ref(false);

    const isOpenConfirmModal = ref(false);
    const isLoadingDeleteConfirm = ref(false);
    const confirmState = reactive<{
      resolve?: (isDeleted: boolean) => void;
      reject?: (isDeleted: boolean) => void;
    }>({});

    const anomaliesChecked = ref<AnomaliesChecked | undefined>();
    const anomalyOptions = computed(() => {
      const anomalyOptions: AnomalyOptions = {
        [AnomalyCode.referenceType]: {},
      };
      if (rentalAgreement.value?.id) {
        anomalyOptions[AnomalyCode.referenceType] = {
          referenceId: rentalAgreement.value.id,
          params: {
            rentalAgreement: {
              deleted: rentalAgreement.value.isArchived || false,
            },
          },
        };
      }
      return anomalyOptions;
    });

    // Actions
    const deleteOrUndoRentalAgreement = async () => {
      if (rentalAgreement.value) {
        const anomalies = await (
          context.refs.transactionsAnomalies as unknown as AnomaliesView
        ).getAnomalies({
          options: {
            [AnomalyCode.referenceType]: {
              referenceId: rentalAgreement.value.id,
              params: {
                rentalAgreement: {
                  deleted: !rentalAgreement.value.isArchived,
                },
              },
            },
          },
        });
        if (!anomalies.length) {
          isOpenConfirmModal.value = true;
          return new Promise(
            (
              resolve: (isDeleted: boolean) => void,
              reject: (isDeleted: boolean) => void
            ) => {
              confirmState.resolve = resolve;
              confirmState.reject = reject;
            }
          );
        } else {
          rentalAgreement.value.isArchived = !rentalAgreement.value.isArchived;
          openModal.value = true;
        }
      }
      return false;
    };

    const deleteCancelRentalAgreement = () => {
      if (rentalAgreement.value) {
        rentalAgreement.value.isArchived = false;
      }
      openModal.value = false;
      isOpenConfirmModal.value = false;
      if (confirmState.resolve) {
        confirmState.resolve(false);
      }
    };

    const deleteConfirmRentalAgreement = async () => {
      isLoadingDeleteConfirm.value = true;
      try {
        await rentalAgreementsStore.updateRentalAgreement({
          ...(rentalAgreement.value as RentalAgreement),
          isArchived: true,
        });
        isOpenConfirmModal.value = false;
        if (confirmState.resolve) {
          confirmState.resolve(true);
        }
      } catch (error) {
        if (
          error instanceof AxiosError &&
          (error.response?.data as BusinessError).type === ANOMALY_ERROR
        ) {
          const {
            anomaliesErrorsSortByPriority,
            anomaliesResolvedSortByPriority,
            operationsErrors,
            operationsResolved,
            operationsWithErrorsAndResolved,
          } = (error.response?.data as BusinessError).data as AnomaliesChecked;
          anomaliesChecked.value = {
            anomaliesErrorsSortByPriority,
            anomaliesResolvedSortByPriority,
            operationsErrors,
            operationsResolved,
            operationsWithErrorsAndResolved,
          };
          if (rentalAgreement.value) {
            rentalAgreement.value.isArchived =
              !rentalAgreement.value.isArchived;
          }
          if (confirmState.resolve) {
            deleteCancelRentalAgreement();
          }
          openModal.value = true;
        }
      } finally {
        isLoadingDeleteConfirm.value = false;
      }
    };

    // Init
    const init = () =>
      (rentalAgreement.value = cloneDeep(
        rentalAgreementsStore.getRentalAgreement(props.rentalAgreementId)
      ));

    watch(
      () => rentalAgreementsStore.getRentalAgreement(props.rentalAgreementId),
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      rentalAgreement,
      anomaliesChecked,
      anomalyOptions,
      openModal,
      isOpenConfirmModal,
      isLoadingDeleteConfirm,
      deleteOrUndoRentalAgreement,
      deleteConfirmRentalAgreement,
      deleteCancelRentalAgreement,
    };
  },
});
