














































import {
  defineComponent,
  ref,
  Ref,
  computed,
  watch,
  PropType,
} from "@vue/composition-api";
import { formatDate } from "@/utils";
import { getMoment } from "@edmp/api";

export default defineComponent({
  name: "DatePicker",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: getMoment().format("YYYY-MM-DD"),
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    error: {
      type: [String, Boolean],
    },
    label: {
      type: String,
      default: "Sélectionner une date",
    },
    refId: {
      type: String,
      default: "datePickerId",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    alwaysReopenOnYear: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Function, Array],
    },
    required: {
      type: Boolean,
      default: false,
    },
    formatDate: {
      type: Function as PropType<(date: string) => string>,
    },
    "hide-details": {
      type: [String, Boolean],
      default: "auto",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "date",
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  setup(props) {
    const picker = ref();
    const date = ref(props.value);
    const datePickerShowed: Ref<boolean> = ref(false);
    const computedValue = computed(() => {
      if (props.value)
        return props.formatDate
          ? props.formatDate(props.value)
          : formatDate(props.value);
    });

    if (props.alwaysReopenOnYear) {
      watch(datePickerShowed, (val) => {
        val && setTimeout(() => (picker.value.activePicker = "YEAR"));
      });
    }

    return {
      date,
      picker,
      computedValue,
      datePickerShowed,
      inputClass: props.dense ? "shrink date-picker" : undefined,
    };
  },
});
