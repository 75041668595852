














































































import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AnomalyCode,
  AnomalyOptions,
  BusinessError,
  RealEstateAsset,
  ANOMALY_ERROR,
  AnomaliesChecked,
} from "@edmp/api";
import { realEstateAssetsStore } from "@/store";

import Anomalies, { AnomaliesView } from "../anomalies/Anomalies.vue";
import { cloneDeep } from "lodash";
import { AxiosError } from "axios";

export interface RealEstateDeleteConfirmView {
  deleteOrUndoRealEstateAsset: () => Promise<boolean>;
}

export default defineComponent({
  name: "RealEstateDeleteConfirm",
  components: { Anomalies },
  props: {
    realEstateAssetId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    // Data
    const realEstateAsset: Ref<RealEstateAsset | undefined> = ref();
    const openModal = ref(false);

    const isOpenConfirmModal = ref(false);
    const isLoadingDeleteConfirm = ref(false);
    const confirmState = reactive<{
      resolve?: (isDeleted: boolean) => void;
      reject?: (isDeleted: boolean) => void;
    }>({});

    const anomaliesChecked = ref<AnomaliesChecked | undefined>();
    const anomalyOptions = computed(() => {
      const anomalyOptions: AnomalyOptions = {
        [AnomalyCode.referenceType]: {},
        [AnomalyCode.objectIdLink]: {},
      };
      if (realEstateAsset.value?.id) {
        anomalyOptions[AnomalyCode.referenceType] = {
          referenceId: realEstateAsset.value.id,
          params: {
            realEstateAsset: {
              deleted: realEstateAsset.value.isArchived || false,
            },
          },
        };
        anomalyOptions[AnomalyCode.objectIdLink] = {
          referenceId: realEstateAsset.value.id,
          params: {
            realEstateAssetDeleted: realEstateAsset.value.isArchived || false,
          },
        };
      }
      return anomalyOptions;
    });

    // Actions
    const deleteOrUndoRealEstateAsset = async () => {
      if (realEstateAsset.value) {
        const anomalies = await (
          context.refs.transactionsAnomalies as unknown as AnomaliesView
        ).getAnomalies({
          options: {
            [AnomalyCode.referenceType]: {
              referenceId: realEstateAsset.value.id,
              params: {
                realEstateAsset: {
                  deleted: !realEstateAsset.value.isArchived,
                },
              },
            },
            [AnomalyCode.objectIdLink]: {
              referenceId: realEstateAsset.value.id,
              params: {
                realEstateAssetDeleted:
                  !realEstateAsset.value.isArchived || false,
              },
            },
          },
        });
        if (!anomalies.length) {
          isOpenConfirmModal.value = true;
          return new Promise(
            (
              resolve: (isDeleted: boolean) => void,
              reject: (isDeleted: boolean) => void
            ) => {
              confirmState.resolve = resolve;
              confirmState.reject = reject;
            }
          );
        } else {
          realEstateAsset.value.isArchived = !realEstateAsset.value.isArchived;
          openModal.value = true;
        }
      }
      return false;
    };

    const deleteCancelRealEstateAsset = () => {
      if (realEstateAsset.value) {
        realEstateAsset.value.isArchived = false;
      }
      openModal.value = false;
      isOpenConfirmModal.value = false;
      if (confirmState.resolve) {
        confirmState.resolve(false);
      }
    };

    const deleteConfirmRealEstateAsset = async () => {
      isLoadingDeleteConfirm.value = true;
      try {
        await realEstateAssetsStore.updateRealEstateAsset({
          ...(realEstateAsset.value as RealEstateAsset),
          isArchived: true,
        });
        isOpenConfirmModal.value = false;
        if (confirmState.resolve) {
          confirmState.resolve(true);
        }
      } catch (error) {
        if (
          error instanceof AxiosError &&
          (error.response?.data as BusinessError).type === ANOMALY_ERROR
        ) {
          const {
            anomaliesErrorsSortByPriority,
            anomaliesResolvedSortByPriority,
            operationsErrors,
            operationsResolved,
            operationsWithErrorsAndResolved,
          } = (error.response?.data as BusinessError).data as AnomaliesChecked;
          anomaliesChecked.value = {
            anomaliesErrorsSortByPriority,
            anomaliesResolvedSortByPriority,
            operationsErrors,
            operationsResolved,
            operationsWithErrorsAndResolved,
          };
          if (realEstateAsset.value) {
            realEstateAsset.value.isArchived =
              !realEstateAsset.value.isArchived;
          }
          if (confirmState.resolve) {
            deleteCancelRealEstateAsset();
          }
          openModal.value = true;
        }
      } finally {
        isLoadingDeleteConfirm.value = false;
      }
    };

    // Init
    const init = () =>
      (realEstateAsset.value = cloneDeep(
        realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId)
      ));

    watch(
      () => realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId),
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      realEstateAsset,
      anomaliesChecked,
      anomalyOptions,
      openModal,
      isOpenConfirmModal,
      isLoadingDeleteConfirm,
      deleteOrUndoRealEstateAsset,
      deleteConfirmRealEstateAsset,
      deleteCancelRealEstateAsset,
    };
  },
});
