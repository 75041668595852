


































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import {
  TaskCode,
  isCommissionedBeforeAmortizationRecovery,
  getReferredIdByTypeWithReferences,
  TaskActivityTypeReference,
  TypeReference,
  AnomalyOptions,
  AnomalyCode,
} from "@edmp/api";

import RealEstateAmortisationForm from "./RealEstateAmortisationForm.vue";
import Title from "../title/Title.vue";
import RealEstateForm from "./RealEstateForm.vue";
import RentalAgreements from "./RentalAgreements.vue";
import {
  productsStore,
  realEstateAssetsStore,
  realEstateAmortisationsStore,
  accountingPeriodsStore,
  taskActivityStore,
  subscriptionsStore,
} from "@/store";

import RealEstateAmortisations from "./RealEstateAmortisations.vue";
import Anomalies from "../anomalies/Anomalies.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import PageHeader from "../../atom/PageHeader.vue";
import { ROUTE_NAMES } from "@/router/routes";
import router from "@/router";
import RealEstateDetailForm from "@/components/core/realEstate/RealEstateDetailForm.vue";
import RealEstateEquipment from "@/components/core/realEstate/RealEstateEquipment.vue";
import RealEstateRents from "@/components/core/realEstate/RealEstateRentsTabs.vue";
import RealEstateRentsTabs from "@/components/core/realEstate/RealEstateRentsTabs.vue";
import RealEstateDeleteConfirm, {
  RealEstateDeleteConfirmView,
} from "./RealEstateDeleteConfirm.vue";

export default defineComponent({
  name: "RealEstate",
  components: {
    RealEstateRentsTabs,
    RealEstateRents,
    RealEstateEquipment,
    RealEstateDetailForm,
    Title,
    Anomalies,
    RealEstateForm,
    RentalAgreements,
    RealEstateAmortisations,
    RealEstateAmortisationForm,
    PageHeader,
    RealEstateDeleteConfirm,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAssetId: {
      type: String as PropType<string | null>,
      required: true,
    },
    rentalUnitId: { type: String, required: false },
    realEstateAmortisationId: { type: String, required: false },
    rentalAgreementId: { type: String, required: false },
  },
  setup(props, context) {
    const tabItemIndex = ref(0);

    const tabIndexParams = context.root.$route.query["tab"];
    if (tabIndexParams) {
      tabItemIndex.value = parseInt(tabIndexParams.toString());
    }

    watch(tabItemIndex, (value) => {
      context.root.$router.push({
        query: { tab: value.toString() },
      });
    });

    const isLoading = ref(true);
    const realEstateAsset = computed(() =>
      realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId)
    );
    const isEditable: Ref<boolean> = ref(false);
    const isEditableAmortisation: Ref<boolean> = ref(false);
    const isNew: Ref<boolean> = ref(false);

    const openLoanModal: Ref<boolean> = ref(false);

    const openModal: Ref<boolean> = ref(false);

    const setEditing = (val: boolean) => (isEditable.value = val);
    const setEditingAmortisation = (val: boolean) =>
      (isEditableAmortisation.value = val);

    const amortizationRecoveryTask = computed(() =>
      taskActivityStore.completedTaskActivities?.find(
        (task) =>
          task.code ===
            TaskCode.AmortizationsResumptionPostAmortizationsUpdate &&
          getReferredIdByTypeWithReferences(
            task.references,
            TaskActivityTypeReference.product
          ) === productsStore.currentId
      )
    );
    const modificationDisabledMessage = computed(() => {
      const amortizationRecoveryTaskAccountingPeriod =
        accountingPeriodsStore.accountingPeriods.find(
          (acc) =>
            acc.id ===
            getReferredIdByTypeWithReferences(
              amortizationRecoveryTask.value?.references,
              TaskActivityTypeReference.accountingPeriod
            )
        );
      if (amortizationRecoveryTaskAccountingPeriod) {
        const year = new Date(
          amortizationRecoveryTaskAccountingPeriod.endAt
        ).getFullYear();
        return (
          "Votre clôture de l’année " +
          year +
          " a été faite, les amortissements ne sont plus modifiables pour assurer le principe de continuité de la comptabilité"
        );
      }
    });
    const isModificationDisabled = computed(() => {
      return (
        realEstateAsset.value &&
        amortizationRecoveryTask.value &&
        isCommissionedBeforeAmortizationRecovery(
          realEstateAsset.value,
          amortizationRecoveryTask.value
        )
      );
    });

    const openArticleRealEstateAmortisationAddingLine = () => {
      return useCrisp().openArticle(
        ArticleEnum.REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE
      );
    };

    // Amortisation
    const realEstateAmortisationList = computed(() =>
      realEstateAmortisationsStore.getRealEstateAmortisationsByRealEstateAssetId(
        props.realEstateAssetId
      )
    );

    watch(
      () => productsStore.currentId,
      () => context.root.$router.push("/activities/?focus=assets-section"),
      { deep: true }
    );

    // Init
    const init = async () => {
      isLoading.value = true;
      await productsStore.switchProduct({ id: props.productId });
      if (props.realEstateAssetId && props.realEstateAssetId !== "new") {
        try {
          await realEstateAssetsStore.fetchRealEstateAsset({
            realEstateAssetId: props.realEstateAssetId,
            productId: productsStore.currentId,
          });
        } catch (err) {
          console.error(err);
          context.root.$router.push({ name: ROUTE_NAMES.Activities });
        }
        isNew.value = false;
        isEditable.value = false;
      } else {
        isNew.value = true;
        isEditable.value = true;
      }
      isLoading.value = false;
    };

    watch(
      () => [props],
      async () => await init(),
      {
        deep: true,
      }
    );

    onBeforeMount(async () => await init());

    onMounted(async () => {
      if (context.root.$route.query["focus"]) {
        setTimeout(() => {
          try {
            context.root.$vuetify.goTo(
              `#${context.root.$route.query["focus"].toString()}`,
              {
                offset: 60,
              }
            );
            context.root.$route.query["focus"] = "";
          } catch (err) {
            // do nothing
          }
        }, 500);
      }
    });

    const validateCreation = (newRealEstateAssetId: string) => {
      context.root.$router.push({
        name: ROUTE_NAMES.RealEstate,
        params: {
          productId: props.productId,
          realEstateAssetId: newRealEstateAssetId,
        },
      });
    };

    const isAmortisationExist = computed(
      () => realEstateAmortisationList.value.length === 0
    );

    const isLoadingDeleteOrUndo: Ref<boolean> = ref(false);
    const deleteOrUndoRealEstateAsset = async () => {
      isLoadingDeleteOrUndo.value = true;
      try {
        const isDeleted = await (
          context.refs[
            `RealEstateDeleteConfirm`
          ] as unknown as RealEstateDeleteConfirmView
        ).deleteOrUndoRealEstateAsset();
        if (isDeleted) {
          router.push({
            name: ROUTE_NAMES.Activities,
            query: { tabIndex: "1" },
          });
        }
      } finally {
        isLoadingDeleteOrUndo.value = false;
      }
    };
    const anomalyOptions = computed(() => {
      const anomalyOptions: AnomalyOptions = {
        [AnomalyCode.referenceType]: {},
        [AnomalyCode.objectIdLink]: {},
      };
      if (!isNew.value && props.realEstateAssetId && realEstateAsset.value) {
        anomalyOptions[AnomalyCode.referenceType] = {
          referenceId: props.realEstateAssetId,
          params: {
            rentalAgreement: {
              deleted: realEstateAsset.value.isArchived || false,
            },
          },
        };
        anomalyOptions[AnomalyCode.objectIdLink] = {
          referenceId: realEstateAsset.value.id,
          params: {
            realEstateAssetDeleted: realEstateAsset.value.isArchived || false,
          },
        };
      }
      return anomalyOptions;
    });

    return {
      ROUTE_NAMES,
      realEstateAsset,
      isEditable,
      isNew,
      setEditing,
      isLoading,
      openModal,
      openLoanModal,
      validateCreation,
      isAmortisationExist,
      openArticleRealEstateAmortisationAddingLine,
      tabItemIndex,
      isEditableAmortisation,
      setEditingAmortisation,
      isModificationDisabled,
      modificationDisabledMessage,
      subscriptionsStore,
      accountingPeriodsStore,

      isLoadingDeleteOrUndo,
      deleteOrUndoRealEstateAsset,
      anomalyOptions,
      TypeReference,
    };
  },
});
