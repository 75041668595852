import { Document, model, PaginateModel, Schema, Model } from "mongoose";
import paginate from "mongoose-paginate-v2";
import { ulid } from "ulid";
import { ArchivedFields, FileInfo, RequireField } from "..";
import { Address, addressSchema, ApiList, IsoDate } from "./Common.model";

/**
 * `IR2072Deductions` — Specific deductions applicable to the IR-2072 Tax Regime
 */
export enum IR2072Deductions {
  NONE = "none",
  BESSON_ANCIEN = "besson_ancien", // si l’immeuble bénéficie d’une déduction spécifique de 26 % au titre du dispositif « Besson ancien » (BOI-RFPI-SPEC-20-10-30-30)
  SCELLIER_ZRR = "scellier_zrr", // si l’immeuble bénéficie d’une déduction spécifique de 26 % au titre du dispositif « Scellier ZRR » (BOI-IR-RICI-230-40-20)
  SCELLIER_INTERMEDIAIRE = "scellier_intermediaire", // si l’immeuble bénéficie d’une déduction spécifique de 30 % au titre du dispositif « Scellier intermédiaire » (BOI-IR-RICI-230-40-10)
  BORLOO_ANCIEN_INTERMEDIAIRE = "borloo_ancien_intermediaire", // si l’immeuble bénéficie d’une déduction spécifique de 30 % au titre du dispositif « Conventionnement ANAH (Borloo ancien) secteur intermédiaire » (BOI-RFPI-SPEC-20-40)
  CARRIERES = "carrieres", // si l’immeuble bénéficie d’une déduction spécifique de 40 % applicable aux Carrières et gisements minéraux productifs de revenus (Réponse ministérielle Labaune n°109030, JO AN 16 janvier 2007, p 562)
  BORLOO_ANCIEN_SOCIAL = "borloo_ancien_social", // si l’immeuble bénéficie d’une déduction spécifique de 45 % au titre du dispositif « Conventionnement ANAH (Borloo ancien) secteur social ou très social » (BOI-RFPI-SPEC-20-40)
  BORLOO_ANCIEN_SOCIAL_2009 = "borloo_ancien_social_2009", // si l’immeuble bénéficie d’une déduction spécifique de 60 % au titre du dispositif « Conventionnement ANAH(Borloo ancien) secteur social ou très social (conventions conclues à compter du 28 mars 2009) » (BOI-RFPI-SPEC-20-40)
  BORLOO_ANCIEN_INTERMEDIAIRE_2009 = "borloo_ancien_intermediaire_2009", // si l’immeuble bénéficie d’une déduction spécifique de 70 % au titre du dispositif « Conventionnement ANAH (Borloo ancien) locations intermédiées, secteur intermédiaire, social ou très social (conventions conclues à compter du 28 mars 2009) » (BOI-RFPI-SPEC- 20-40)
  COSSE_INTERMEDIAIRE_B = "cosse_intermediaire_b", // si l’immeuble bénéficie d’une déduction spécifique de 15 % au titre du dispositif « Conventionnement ANAH (Cosse) secteur intermédiaire » en zone B2
  COSSE_INTERMEDIAIRE_A = "cosse_intermediaire_a", // si l’immeuble bénéficie d’une déduction spécifique de 30 % au titre du dispositif « Conventionnement ANAH (Cosse) secteur intermédiaire » en zones A bis, A et B1
  COSSE_SOCIAL_B = "cosse_social_b", // si l’immeuble bénéficie d’une déduction spécifique de 50 % au titre du dispositif « Conventionnement ANAH (Cosse) secteur social ou très social » en zone B2 et en zone C lorsque la convention prévoit la réalisation de travaux d’amélioration
  COSSE_SOCIAL_A = "cosse_social_a", // si l’immeuble bénéficie d’une déduction spécifique de 70 % au titre du dispositif « Conventionnement ANAH (Cosse) secteur social ou très social » en zones A bis, A et B1
  COSSE_INTERMEDIATION = "cosse_intermediation", // si l´immeuble bénéficie d’une déduction spécifique de 85 % au titre du dispositif « Conventionnement ANAH (Cosse) secteur intermédiaire, social ou très social dans le cadre d’une intermédiation locative sociale » quelle que soit la zone du territoire (A bis, A, B1, B2 ou C),
}

/**
 * `IR2072Nature1` — Real Estate Asset Type, first list
 *
 * Dans la sous-colonne « A » de la colonne « Nature de l’immeuble » (dans la déclaration en ligne dans le
 * premier menu déroulant de la rubrique « Nature de l’immeuble » du point « 2. Autres renseignements »
 * du cadre « II - LISTE DES IMMEUBLES DÉTENUS PAR LA SOCIÉTÉ IMMOBILIÈRE » de l’onglet « FORMULAIRE
 * 2072S »), il convient de porter les références suivantes
 */
export enum IR2072NatureA {
  IMMEUBLE_URBAIN = "U", // immeuble urbain
  IMMEUBLE_RURAL = "R", // immeuble rural
  IMMEUBLE_DE_RAPPORT = "IR", // immeuble de rapport
  APPARTEMENT = "AP", // appartement
  MAISON = "M", // maison
  PARKING = "P", // parking
  AUTRE = "AU", // autres, zone libre à prévoir
}

export const NatureLabel = {
  U: "Immeuble Urbain",
  R: "Immeuble Rural",
  IR: "Immeuble de rapport",
  AP: "Appartement",
  M: "Maison",
  P: "Parking",
  AU: "Autre",
} as const;

/**
 * `IR2072Nature2` — Real Estate Asset Type, second list
 *
 * Dans la sous-colonne « B » de la colonne « Nature de l’immeuble » (dans la déclaration en ligne dans le
 * second menu déroulant de la rubrique « Nature de l’immeuble » du point « 2. Autres renseignements » du
 * cadre « II - LISTE DES IMMEUBLES DÉTENUS PAR LA SOCIÉTÉ IMMOBILIÈRE » de
 * l’onglet « FORMULAIRE 2072S »), il convient de porter les références suivantes selon la nature de l’immeuble:
 */
export enum IR2072NatureB {
  BATI = "B", // bâti
  NON_BATI = "NB", // non-bâti
}
/**
 * `DPE` — Energy Performance Diagnosis
 */
export enum DPE {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  UNKNOWN = "Non contrôlé",
}
/**
 * `LmnpEstate` — LmnpEstate based type
 */
interface LmnpEstate {
  marketPrice?: number;
  entryDateActivityLmnp?: IsoDate;
  boughtFee?: number;
  agencyFee?: number;
}
/**
 * ` FileInfoArray` - For multiple files upload in real Estate Asset
 */
export type FileInfoArray = {
  file: FileInfo;
}[];

/**
 * `Asbestos` —  A Asbestos declaration
 */
export interface Asbestos {
  documentAsbestosId?: string;
  diagnosisDate?: string;
  asbestosStatus?: string;
}
export const asbestosSchema = new Schema<Asbestos>({
  documentAsbestosId: String,
  diagnosisDate: String,
  asbestosStatus: String,
});
/**
 * Dpe
 */
export interface DpeInformation {
  dpeGrade: DPE;
  documentDpeId?: string;
  diagnosisDate?: string;
}
export const dpeSchema = new Schema<DpeInformation>({
  dpeGrade: String,
  documentDpeId: String,
  diagnosisDate: String,
});

/**
 * `RealEstateAssetModel` — A real-estate property/asset (typically, a building)
 * owned by a company
 */
export type RealEstateAsset<IsArchived extends boolean = boolean> = {
  id: string;
  productId: string;
  name: string; // The name of the building/property
  address: Address;
  rentalUnitCount?: number; // Number of Rental Units in this Real Estate Asset
  taxDeduction: IR2072Deductions;
  nature?: IR2072NatureA;
  built?: IR2072NatureB;
  commissioningAt?: IsoDate;
  boughtAt?: IsoDate;
  boughtPrice?: number;
  groundShare?: number;
  soldAt?: IsoDate;
  documentId?: string;
  asbestos?: Asbestos; //Asbestos declaration
  createdAt: string;
  updatedAt: string;
  dpe: DpeInformation; //Dpe declaration
} & LmnpEstate &
  ArchivedFields<IsArchived>;
export type NewRealEstateAsset = Omit<
  RealEstateAsset,
  "id" | "isArchived" | "isArchivedAt" | "createdAt" | "updatedAt"
>;
export type RealEstateAssetUpdate = RequireField<
  Partial<Omit<RealEstateAsset, "productId" | "isArchivedAt" | "createdAt" | "updatedAt">>,
  "id"
>;
export type RealEstateAssetUpdateInternal = RequireField<
  Partial<Omit<RealEstateAsset, "createdAt" | "updatedAt">>,
  "id"
>;

export type RealEstateAssetRemove = RequireField<
  Partial<Omit<RealEstateAsset, "productId" | "createdAt" | "updatedAt">>,
  "id"
>;

// List of RealEstateAssets with Pagination
export type RealEstateAssets = ApiList<RealEstateAsset>;

const realEstateAssetSchema = new Schema<Document<string>, Model<RealEstateAsset>>(
  {
    _id: { type: String, default: () => ulid() },
    name: { type: String, required: true, maxlength: 200 },
    address: {
      type: addressSchema,
    },
    productId: { type: String, index: true, required: true },
    rentalUnitCount: Number,
    taxDeduction: {
      type: String,
      enum: Object.values(IR2072Deductions), // Mongoose doesn't support Typescript ENUM
    },
    nature: {
      type: String,
      enum: Object.values(IR2072NatureA), // Mongoose doesn't support Typescript ENUM
    },
    built: {
      type: String,
      enum: Object.values(IR2072NatureB), // Mongoose doesn't support Typescript ENUM
    },
    commissioningAt: String,
    boughtAt: String,
    boughtPrice: Number,
    groundShare: Number,
    soldAt: String,
    documentId: String,
    asbestos: { type: asbestosSchema },
    marketPrice: Number,
    entryDateActivityLmnp: String,
    boughtFee: Number,
    agencyFee: Number,
    dpe: { type: dpeSchema },

    isArchived: { type: Boolean },
    isArchivedAt: { type: String },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RealEstateAssetDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

realEstateAssetSchema.plugin(paginate);

export type RealEstateAssetDocument = RealEstateAsset & Document<string>;

// Name of the collection in third argument
export const RealEstateAssetModel = model<RealEstateAssetDocument, PaginateModel<RealEstateAssetDocument>>(
  "RealEstateAsset",
  realEstateAssetSchema,
  "RealEstateAssets"
);

export const getNature = (realEstateAsset: RealEstateAsset): string | undefined =>
  Object.entries(IR2072NatureA).find(([, value]) => value === realEstateAsset.nature)?.[0];

// API
export namespace RealEstateAssetsService {
  export type CreateIn = NewRealEstateAsset;
  export type CreateOut = RealEstateAsset;

  export type ListIn = Partial<Pick<RealEstateAsset, "productId" | "isArchived">>;
  export type ListOut = RealEstateAsset[];

  export type ListPaginateIn = Partial<Pick<RealEstateAsset, "productId">> & { page: number; limit: number };
  export type ListPaginateOut = RealEstateAssets;

  export type GetIn = Pick<RealEstateAsset, "id">;
  export type GetOut = RealEstateAsset;

  export type UpdateIn = RealEstateAssetUpdate;
  export type UpdateOut = RealEstateAsset;

  export type DeleteIn = Pick<RealEstateAsset, "id">;
  export type DeleteOut = boolean;

  export type AddDocumentsIn = Pick<RealEstateAsset, "id" | "productId" | "asbestos" | "dpe"> & {
    files: FileInfoArray;
  };
  export type AddDocumentsOut = RealEstateAsset;

  export type DeleteDocumentIn = Pick<RealEstateAsset, "id" | "documentId" | "asbestos" | "dpe">;
  export type DeleteDocumentOut = RealEstateAsset;
}

/**
 * * Lib
 */
export namespace RealEstateAssetsLib {
  export const isArchived = <REA extends RealEstateAsset | RealEstateAssetUpdateInternal>(
    realEstateAsset: REA
  ): realEstateAsset is REA & { isArchived: true } => !!realEstateAsset.isArchived;
  export const isNotArchived = (realEstateAsset: RealEstateAsset): realEstateAsset is RealEstateAsset<false> =>
    !realEstateAsset.isArchived;
}
